import { buildQuery } from "@/utils.js";


export async function getMenuPermissions(role) {
  const url_query = buildQuery({
    role: role,
  });
  if (role == null || role == "") {
    throw new Error("Error get section permissions");
  }
  const url = "/api/permissions/menu?" + url_query
  const response = await fetch(url, {
    method: "GET",

  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error get menu permissions");
  }
}

export function getPermissionByRole(id_role) {
  const url = "/api/permissions/permission-role/" + id_role;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getPermissionByRoleName(name_role) {
  const url = "/api/permissions/permission-role-name/" + name_role;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}
