<template>
  <v-card cols="12" xl="12" lg="12" md="12" sm="12">
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <ConfirmDialog ref="warning"></ConfirmDialog>
    <v-card-title v-if="showTitle" class="justify-start font-weight-bold" cols="12" xl="12" lg="12" md="12" sm="12">
      <v-icon left color="primary">
        mdi-close-circle-outline
      </v-icon>
      <span class="table-title">{{ $t('alertas') }}</span>
    </v-card-title>

    <v-data-table :headers="activeAlarms.headers" :items="activeAlarms.items" :options="activeAlarms.options"
      :loading="activeAlarms.loading" :loading-text="$t('cargando_datos')" :no-data-text="$t('alarmas_no_encontradas')"
      class="elevation-0" color="accent">
      <template v-slot:[`header.id`]>
        <v-icon class="secondary--text">mdi-bell-outline</v-icon>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <router-link v-if="userData.user_consent" :to="`/flota/alarma/detalle/${item.id}`">
          <v-icon v-if="item.alarm.alarm_type == 'Mantenimiento'" class="secondary--text">mdi-hammer-wrench</v-icon>
          <v-icon v-else class="secondary--text">mdi-alert-outline</v-icon>
        </router-link>
        <span v-else> {{ item.id }}</span>
      </template>
      <template v-slot:[`item.machine_number`]="{ item }">
        <router-link v-if="userData.user_consent" :to="`/flota/maquina/detalle/${item.machine_id}`">
          {{ item.machine_number }}
        </router-link>
        <span v-else> {{ item.machine_number }}</span>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <span v-if="item.alarm.description">{{ $t(item.alarm.description) }}</span>
        <span v-else class="pl-8">-</span>
      </template>
      <template v-slot:[`item.alarm_date`]="{ item }">
        <span>{{ getFormattedDate(item.alarm_date) }}</span>
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <router-link :to="getClientRoute(item)">
          <span>{{ formatClientsName(item.machine.client) }}</span>
        </router-link>
      </template>
      <template v-slot:[`item.intervention_date`]="{ item }">
        <span v-if="item.intervention_date">{{ getFormattedDate(item.intervention_date) }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:[`item.severity`]="{ item }">
        <v-icon small :color="`severity${item.severity}`">
          mdi-checkbox-blank-circle
        </v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }" v-if="isAdminOrDistributor() && userData.user_consent">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.client.id && item.client.person.email" small color="background" @click="sendEmail(item)"
              v-bind="attrs" v-on="on">
              mdi-comment-outline
            </v-icon>
            <v-icon v-else small color="background" disabled> mdi-comment-outline </v-icon>
          </template>
          <span>{{ $t('enviar_alerta') }}</span>
        </v-tooltip>
        <v-tooltip top v-if="item.intervention_date == null" class="ml-2">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.client_id && item.client_name" small color="background" @click="resetAlarm(item)"
              v-bind="attrs" v-on="on">
              mdi-check-circle-outline
            </v-icon>
          </template>
          <span v-if="item.alarm.alarm_type == 'Mantenimiento'">{{ $t('resetear_mantenimiento') }}</span>
          <span v-else>{{ $t('resetear_alarma') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getFormattedDateToLocaleUTC } from "@/utils.js";
import { sendAlert } from '@/api/alarms';
import ConfirmDialog from './ConfirmDialog.vue';
import { formatClientName } from "@/utils"

export default {
  name: "alarm-table",
  props: {
    activeAlarms: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    ConfirmDialog
  },
  methods: {
    getFormattedDate(date) {
      return getFormattedDateToLocaleUTC(date)
    },
    getClientRoute(item) {
      if (item.machine.client.is_distributor)
        return `/distribuidor/detalle/${item.machine.client.id}`
      else
        return `/cliente/detalle/${item.machine.client.id}`
    },
    sendEmail(alarm) {
      if (!alarm.client.id || !alarm.client.person.email) {
        this.$refs.warning.open(
          "Error",
          `La máquina '${alarm.machine_number}' no tiene clientes asociados por lo que no puede enviarse la alerta.`,
          { color: "primary", cancel: false, btnAcceptName: "Ok" }
        );
      } else {
        this.$refs.confirm
          .open(
            "Confirmación",
            `¿Está seguro de que desea enviar la alerta al correo '${alarm.machine.client.person.email}'?`,
            { color: "primary" }
          )
          .then((confirm) => {
            if (confirm) {
              sendAlert(alarm.id)
                .then((response) => {
                  if (response.status == "ok") {
                    this.closeAlert();
                    this.save_success = true;
                    this.save_success_msg =
                      "Se ha enviado correctamente la alerta por email";
                  } else {
                    this.closeSuccessAlert();
                    this.error = true;
                    this.error_msg = "Error enviando la alerta por email";
                  }
                })
                .catch((error) => {
                  console.log(error)
                  this.closeSuccessAlert();
                  this.error = true;
                  this.error_msg = "Error enviando la alerta por email";
                });
            }
          });
      }
    },
    closeAlert() {
      this.error = false;
      this.error_msg = null;
    },
    closeSuccessAlert() {
      this.save_success = false;
      this.save_success_msg = null;
    },
    isAdminOrDistributor() {
      return !!(this.userData.distributor || this.userData.role === "admin" || this.userData.role === "comercial");
    },
    resetAlarm(item) {
      if (item.alarm.alarm_type == 'Mantenimiento')
        this.$router.push("/flota/alarma/reset/mantenimiento/" + item.alarm.id)
      else
        this.$router.push("/flota/alarma/reset/alarma/" + item.alarm.id)
    },
    formatClientsName(client) {
      return formatClientName(client)
    },
  }
}
</script>
<style lang="scss" scoped>
.table-title {
  color: rgb(205, 17, 62) !important;
  text-transform: uppercase;
}

.my-table .v-data-table-row {
  background-color: aquamarine !important;
}

.my-table-header {
  background-color: rgb(205, 17, 62) !important;
  text-transform: uppercase;
}
</style>