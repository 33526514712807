<template>
  <v-card cols="12" xl="12" lg="12" md="12" sm="12">
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <ConfirmDialog ref="warning"></ConfirmDialog>
    <v-card-title class="justify-start font-weight-bold" cols="12" xl="12" lg="12" md="12" sm="12">
      <v-icon left :color="tableTitleColor">
        mdi-wrench-outline
      </v-icon>
      <span :class="`${tableTitleColor}--text text-uppercase`">
        {{ tableTitle }}
      </span>
    </v-card-title>
    <v-data-table :headers="maintenances.headers" :items="maintenances.items" :options="maintenances.options"
      :loading="maintenances.loading" :loading-text="$t('maintenance_table.cargando_mantenimientos')"
      :no-data-text="$t('maintenance_table.mantenimientos_no_encontrados')"
      :no-results-text="$t('maintenance_table.mantenimientos_no_encontrados')" color="accent" :footer-props="{
        'items-per-page-text': $t('paginas_fila'),
        pageText: '{0}-{1} de {2}',
      }">
      <template v-slot:[`header.id`]>
        <v-icon class="secondary--text">mdi-tractor</v-icon>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <router-link :to="`/flota/alarma/detalle/${item.id}`">
          {{ item.id }}
        </router-link>
      </template>
      <template v-slot:[`item.machine_number`]="{ item }">
        <router-link :to="`/flota/maquina/detalle/${item.machine_id}`">
          {{ item.machine_number }}
        </router-link>
      </template>
      <template v-slot:[`item.machine.hours`]="{ item }">
        <span>{{ item.alarm.hours - (item.current_working_hours - item.machine.total_work_hours) }}</span>
      </template>
      <template v-slot:[`item.alarm.description`]="{ item }">
        <span v-if="item.alarm.description">{{ item.alarm.description }}</span>
        <span v-else class="pl-8">-</span>
      </template>
      <template v-slot:[`item.alarm_date`]="{ item }">
        <span v-if="item.alarm_date">{{
          getFormattedDate(item.alarm_date)
        }}</span>
      </template>
      <template v-slot:[`item.intervention_date`]="{ item }">
        <span v-if="item.intervention_date">{{
          getFormattedDate(item.intervention_date)
        }}</span>
      </template>
      <template v-slot:[`item.model`]="{ item }">
        <span>{{ item.machine.model.name }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }" v-if="isAdminOrDistributor()">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.client.id && item.client.person.email" small color="background" @click="sendEmail(item)"
              v-bind="attrs" v-on="on">
              mdi-comment-outline
            </v-icon>
            <v-icon v-else small color="background" disabled> mdi-comment-outline </v-icon>
          </template>
          <span>{{ $t('enviar_alerta') }}</span>
        </v-tooltip>
        <v-tooltip top v-if="item.intervention_date == null && resetAllowed" class="ml-2">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small color="background" @click="resetMaintenance(item)"
              v-bind="attrs" v-on="on">
              mdi-check-circle-outline
            </v-icon>
          </template>
          <span>{{ $t('resetear_mantenimiento') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { sendAlert } from '@/api/alarms';
import ConfirmDialog from './ConfirmDialog.vue';
import { getFormattedDateToLocaleUTC, getStringSnakeCase } from "@/utils.js";

export default {
  name: "next-maintenance-table",
  props: {
    tableTitle: {
      type: String,
      required: true,
    },
    tableTitleColor: {
      type: String,
      required: false,
      default: 'secondaryLight'
    },
    maintenances: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    resetAllowed: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      selectedMaintenanceId: null
    }
  },
  methods: {
    sendEmail(alarm) {
      if (!alarm.client.id || !alarm.client.person.email) {
        this.$refs.warning.open(
          "Error",
          `La máquina '${alarm.machine_number}' no tiene clientes asociados por lo que no puede enviarse la alerta.`,
          { color: "primary", cancel: false, btnAcceptName: "Ok" }
        );
      } else {
        this.$refs.confirm
          .open(
            "Confirmación",
            `¿Está seguro de que desea enviar la alerta al correo '${alarm.machine.client.person.email}'?`,
            { color: "primary" }
          )
          .then((confirm) => {
            if (confirm) {
              sendAlert(alarm.id)
                .then((response) => {
                  if (response.status == "ok") {
                    this.closeAlert();
                    this.save_success = true;
                    this.save_success_msg =
                      "Se ha enviado correctamente la alerta por email";
                  } else {
                    this.closeSuccessAlert();
                    this.error = true;
                    this.error_msg = "Error enviando la alerta por email";
                  }
                })
                .catch(() => {
                  this.closeSuccessAlert();
                  this.error = true;
                  this.error_msg = "Error enviando la alerta por email";
                });
            }
          });
      }
    },
    isAdminOrDistributor() {
      return !!(this.userData.distributor || this.userData.role === "admin" || this.userData.role === "comercial");
    },
    resetMaintenance(item) {
      this.selectedMaintenanceId = item.id
      this.$router.push("/flota/alarma/reset/mantenimiento/" + this.selectedMaintenanceId);
    },
    getFormattedDate(date) {
      return getFormattedDateToLocaleUTC(date)
    },
    closeAlert() {
      this.error = false;
      this.error_msg = null;
    },
    closeSuccessAlert() {
      this.save_success = false;
      this.save_success_msg = null;
    },
    getTranslatedItemText(name) {
      return this.$t(getStringSnakeCase(name))
    },
  }
}
</script>
