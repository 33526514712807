<template>
  <v-list-item v-if="section.name != 'UrlsMenu'" :key="child.name" :to="section.path + '/' + child.path">
    <v-list-item-icon>
      <v-icon color="black">{{ child.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ getTranslatedItemText(child.name) }}</v-list-item-title>
  </v-list-item>
  <v-list-item v-else :key="child.name + child.id" :href="child.value" target="_blank">
    <v-list-item-title>{{ getTranslatedItemText(child.name) }}</v-list-item-title>
  </v-list-item>
</template>
<script>
import { getStringSnakeCase } from "@/utils";

export default {
  name: "menu-item",
  props: {
    section: {
      type: Object,
      required: true,
    },
    child: {
      type: Object,
      required: true
    }
  },
  methods: {
    getTranslatedItemText(name) {
      return this.$t(getStringSnakeCase(name))
    },
  }
}
</script>
