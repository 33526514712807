<template>
  <div>
    <MainTitle :items="breadItems" title="Clientes" icon="mdi-account" />
    <v-container fluid class="pr-8 pl-8">
      <ConfirmDialog ref="confirm"></ConfirmDialog>
      <v-row>
        <v-col class="pt-0" cols="12" xl="12" lg="12" md="12" sm="12">
          <v-alert class="text-center" :value="this.save_success" dismissible outlined text color="green"
            @input="closeSuccessAlert">
            <v-icon color="success" class="pb-1"> mdi-check-circle </v-icon>
            {{ this.save_success_msg }}
          </v-alert>
        </v-col>
      </v-row>

      <v-card>
        <v-card-title>
          <v-row align="center" justify="center">
            <v-col xl="auto" lg="auto" md="auto" sm="auto" cols="12">
              <v-btn v-if="isAdminOrDistributor" class="white--text px-4 mr-4" depressed rounded color="secondary"
                type="button" @click="newClient" block>
                <v-icon left> mdi-plus </v-icon>
                {{ $t('nuevo_cliente') }}
              </v-btn>
            </v-col>
            <v-col xl="auto" lg="auto" md="auto" sm="auto" cols="12">
              <v-btn v-if="userData != null && userData.role == 'admin'" depressed rounded class="white--text px-4 mr-4"
                color="blueCustom" @click="importClient" block>
                <v-icon left> mdi-arrow-expand-up </v-icon>
                {{ $t('carga_masiva') }}
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col xl="auto" lg="auto" md="auto" sm="auto" cols="12">
              <v-btn-toggle dense class="mr-4" v-model="activeStatus" @change="onActiveFilterChanged">
                <v-btn :class="{ 'primary white--text': activeStatus === 'all' }" class="rounded-pill black--text"
                  color="menu" value="all"> {{ $t('todas') }} </v-btn>
                <v-btn :class="{ 'primary white--text': activeStatus === 'active' }" class="rounded-pill black--text"
                  color="menu" value="active"> {{ $t('activas') }} </v-btn>
                <v-btn :class="{ 'primary white--text': activeStatus === 'inactive' }" class="rounded-pill black--text"
                  color="menu" value="inactive"> {{ $t('sin_activar') }} </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-spacer />
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('buscar')"
                single-line></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table :headers="table.headers" :items="table.items" :options="table.options" :loading="table.loading"
          :search="table.search" :loading-text="$t('cargando_datos')" :no-data-text="$t('cliente_no_encontrado')"
          class="elevation-0 " color="accent">
          <template v-slot:[`item.person.name`]="{ item }">
            <router-link v-if="userData.user_consent" :to="`/cliente/detalle/${item.person.id}`">
              {{ formatClientsName(item) }}
            </router-link>
            <span v-else> {{ formatClientsName(item) }}</span>
          </template>
          <template v-if="userData.user_consent" v-slot:[`item.actions`]="{ item }">
            <v-icon small color="background" class="mr-2" @click="editItem(item)">
              mdi-pencil-outline
            </v-icon>
            <v-icon small color="background" class="mr-2" @click="deleteItem(item)">
              mdi-delete-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MainTitle from "@/components/MainTitle";
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { getClients } from "@/api/users";
import { deleteClient } from "@/api/clients";
import { formatClientName } from "@/utils"

export default {
  name: "clients-item",

  components: {
    MainTitle,
    ConfirmDialog,
  },

  data() {
    return {
      breadItems: [
        {
          text: 'Clientes',
          disabled: true,
          href: 'clientes',
          id: 0
        },
      ],
      activeStatus: "all",
      table: {
        loading: true,
        search: null,
        options: {
          itemsPerPage: 15,
        },
        headers: [],
        items: [],
        selected: [],
      },
      editorSuccess: null,
      creationSuccess: null,
      save_success: false,
      save_success_msg: null,
      userData: null
    };
  },

  methods: {
    newClient: function () {
      this.$router.push("/cliente/nuevo");
    },
    importClient() {
      this.$router.push("/clientes/importacion");
    },
    refreshAllClients: function () {
      getClients(this.activeStatus)
        .then((response) => {
          this.table.items = response.clients;
          this.refreshTexts()
          this.table.loading = false;
        })
        .catch(() => {
          this.table.loading = false;
          this.table.items = [];
        });
    },
    editItem: function (client) {
      this.$router.push("/cliente/editar/" + client.id);
    },
    deleteItem: function (client) {
      this.$refs.confirm
        .open(
          this.$t('confirmacion'),
          this.$t('eliminar_cliente'),
          { color: "primary" }
        )
        .then((confirm) => {
          if (confirm) {
            deleteClient(client.id)
              .then((response) => {
                if (response.status == "ok") {
                  this.refreshAllClients()
                  this.closeSuccessAlert();
                  this.save_success = true;
                  this.save_success_msg = this.$t('cliente_eliminado');
                } else {
                  this.closeSuccessAlert();
                  this.error = true;
                  this.error_msg = this.$t('cliente_guardado_error')
                }
              })
              .catch((error) => {
                console.log(error)
                this.closeSuccessAlert();
                this.error = true;
                this.error_msg = "Error enviando la alerta por email";
              });
          }
        });
    },
    onActiveFilterChanged: function () {
      this.refreshAllClients();
    },
    closeSuccessAlert() {
      this.save_success = false;
      this.save_success_msg = null;
    },
    refreshTexts() {
      this.table.headers = [
        { text: this.$t("nombre"), value: "person.name" },
        { text: this.$t("telefono"), value: "person.phone" },
        { text: this.$t("email"), value: "person.email" },
        { text: this.$t("flota"), value: "machine_count" },
        { text: this.$t("poblacion"), value: "person.city" },
        { text: this.$t("pais"), value: "person.country" },
        { text: this.$t("acciones"), value: "actions", sortable: false },
      ]
    },
    formatClientsName(client) {
      return formatClientName(client)
    },
  },
  created() {
    const editorSuccess = this.$route.query.editorSuccess;
    this.editorSuccess = editorSuccess;
    const creationSuccess = this.$route.query.creationSuccess;
    this.creationSuccess = creationSuccess;
    this.userData = JSON.parse(window.sessionStorage.getItem('vuex'));
  },
  computed: {
    isAdminOrDistributor() {
      const role = this.userData.role;

      return !!(role === 'admin' || role === 'gerente');
    },
  },
  mounted() {
    this.refreshAllClients();
    if (this.editorSuccess) {
      this.save_success = true;
      this.save_success_msg = this.$t('cliente_guardado');
    }
    if (this.creationSuccess) {
      this.save_success = true;
      this.save_success_msg = this.$t('cliente_guardado');
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.refreshTexts()
    }
  }
};
</script>
