export const getTranslations = async () => {
  const response = await fetch("/api/public/translations");
  if (!response.ok) {
    throw new Error("Error al cargar las traducciones");
  }
  const result = await response.json();

  const translations = {};
  result.data.forEach((item) => {
    translations[item._id] = item.translations;
  });

  return translations;
};
