<template>
  <div>
    <table-header :value="value" :backgroundColor="backgroundColor" :textColor="textColor"
      :label="label.toUpperCase()" :icon="icon" :iconColor="iconColor"></table-header>
    <v-container class="custom-card-text">
      <v-container v-for="cardElement in cardElements" :key="cardElement.label">
        <table-row :value="cardElement.value" :backgroundColor="'white'" :textColor="backgroundColor"
          :label="cardElement.label.toUpperCase()" :icon="cardElement.icon"
          :iconColor="backgroundColor" class="mb-1 text-start"></table-row>
        <v-divider height="3px" :color="dividerColor"></v-divider>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import TableHeader from '@/components/TableHeader.vue';
import TableRow from '@/components/TableRow.vue';

export default {
  name: "text-card-multiple-item",
  components: { TableHeader, TableRow },
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      required: true,
    },
    cardElements: {
      type: [],
      required: true,
    },
    dividerColor: {
      type: String,
      required: true
    }
  },
};
</script>
