import Vue from "vue";
import VueI18n from "vue-i18n";
import { getTranslations } from "@/api/translations";

Vue.use(VueI18n);

const createI18n = async () => {
  const translations = await getTranslations();
  return new VueI18n({
    locale: "es", // Idioma por defecto
    messages: translations,
  });
};

// Función para inicializar i18n y manejar posibles errores
export default async function initI18n() {
  try {
    const i18n = await createI18n();
    return i18n;
  } catch (error) {
    console.error("Error al cargar las traducciones:", error);
    return new VueI18n({
      locale: "es",
      messages: { en: {}, es: {} }, // Mensajes vacíos en caso de error
    });
  }
}
